[
  {
    "id": 1,
    "Order": "Hypocreales",
    "Family": "Nectriaceae",
    "Genus species": "Fusarium xylarioides",
    "Strain": "CS21-175",
    "Host species": "Coffea arabica",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "CGGAGGGATCATTACCGAGTTTACAACTCCCAAACCCCTGTGAACATACCAATTGTTGCCTCGGCGGATCAGCCCGCTCCCGGTAAAACGGGACGGCCCGCCAGAGGACCCCCAAACTCTGTTTCTATATGTAACTTCTGAGTAAAACCACAAATAAATCAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCAAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGTATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCCCCCGGGTTTGGTGTTGGGGATCGGCGAGCCCTCGCGGCAAGCCGGCCCCGAAATACAGTGGCGGTCTCGCTGCAGCTTCCATTGCGTAGTAGTAAAACCCTCGCAACTGGTACGCGGCGCGGCCAAGCCGTTAAACCCCCAACTTCTGAATGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCAATAAGCGG",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 2,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum spp.",
    "Strain": "GOAN1.1",
    "Host species": "Ronabea latifolia",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "CAGCGGAGGGATCATTACTGAGTTTACGCTCTATAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGCGACCCTCCCGGCCTCCCGCCTCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 3,
    "Order": "Xylariales",
    "Family": "Xylariaceae",
    "Genus species": "Xylaria cubensis",
    "Strain": "GOAN3.4",
    "Host species": "Ronabea latifolia",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GGATCATTTACTGAAGTTTATYCCAAAACTYCCCAACCCTTTGTGAACTTACCACTTGTTGCCTCGGCGWAGTTGTGCTACCCTATAGCTACCCTGTAGCTACCCGGGAACACATTCCAAGCTCGCCAGAGGACCTACCAACTCTGTTTTATACTGTATCTCTGAACTTTATAACTAAATAAGTTAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCATTAGTATTCTAGTGGGCATGCCTATTCGAGCGTCATTTCAACCCTTAAGCCTCAGTTGCTTAGCGTTGGGACTCTACGACCTATTATAGCGTAGTTCCTTAAAGGTAGTGGCGGAGTTATAGCACACTCTAAGCGTAGTAATTCTCTCTCGCTTCTTGTAGTGGTTATAGTTGCTAGCCATAAAACACCCCCCATTTTAATGGTTGACCTCGGATTAGGTAGGAATACCCGCTGAACTTAAGCATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 4,
    "Order": "Xylariales",
    "Family": "Xylariaceae",
    "Genus species": "Nemania aff. abortiva",
    "Strain": "GOAV1.2A",
    "Host species": "Ronabea latifolia",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "TAGAGAGTTCTCATAAAACTCCCAAACCCATGTGAACATACCTTTTGTTGCCTCGGCAGGTCGTGCCGGCGCTTAACCGCGTAGTACCCTACCCCGTAGGGCCTACCCGGGACGCGCCGGCGGCCCCCCTGCCGGCGGCCCACGAAACTCTGTTTAGCATATTGAATTCTGAACGTATAACTAAATCAGTTAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCATTAGTATTCTAGTGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCCCCTGTTGCTTGGTGTTGGGAGCCTACGGGGTCACCCGTAGCTCCTTAAAGTTAGTGGCGGAGTCGGTTCACACTCTAGACGTAGTAGATTTTCACCTCGCCTGTAGTGCTGGACCGGTCCCCTGCCGTAAAACGCCCCTATTTCTAAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 5,
    "Order": "Xylariales",
    "Family": "Xylariaceae",
    "Genus species": "Xylaria cubensis",
    "Strain": "GOAV2.4",
    "Host species": "Ronabea latifolia",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "TCATTAAAGAGTTCTATAACTCCCAAACCCATGTGAACATACCTAACGTTGCCTCGGCAGGTCGCGCCTACCTCGTAGTCTTACCCTGTAAGACTTACCCGGGAGGTGCGGGTAAGCCTGCCGGCGGCCCACCAAACTCTGTTTGATATTGAATTCTGAACCTATAACTAAATAAGTTAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCATATTGCGCCCATTAGTATTCTAGTGGGCAGGACCGTTCGAGCGTCATTTCAACCCTTAAGCCTTAGTTGCTTAGTGTTGGGAGCCTACGGTAACGTAGCTCCTCAAAGTTAGTGGCGGAGTTGGTTCACACTCTAGACGTAGTAATTTTTTATCTCGCCTATTAGTTGGACTGATCCCCTGCCGTAAAACCCCAACTTCTCAAGGTTGACCTCGAATCGGTTCAGACAAACTCGCTAAATTGAAGCATATCAATAA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 6,
    "Order": "Diaporthales",
    "Family": "Diaporthaceae",
    "Genus species": "Diaporthe aff. phaseolorum",
    "Strain": "GOBV2.3",
    "Host species": "Isertia hankeana",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "TGCTGGAACGCGCTTCGGCGCACCCAGAAACCCTTTGTGAACTTATACCTATTTGTTGCCTCGGCGTAGGCCGGCCTCTTCACTGAGGCCCCCTGGAAACGGGGAGCAGCCCGCCGGCGGCCAACTAAACTCTTGTTTCTATAGTGAATCTCTGAGTAAAAACATAAATGAATCAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTTAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCTCTGGTATTCCGGAGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCCTGGCTTGGTGATGGGGCACTGCCTTCTAGCGAGGGCAGGCCCTGAAATCTAGTGGCGAGCTCGCTAGGACCCCGAGCGTAGTAGTTATATCTCGTTCTGGAAGGCCCTGGCGGTGCCCTGCCGTTAAACCCCCAACTTCTGAAAATTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCAATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 7,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum theobromicola",
    "Strain": "GOCN2.3B",
    "Host species": "Warszewiczia coccinea",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GGATATTACTGAAGTTACGCTYTACAACCCTTTGTGAAATACCTATAACTGTTGCTTCGGCGGGCAGGGTCTCCGTGACCCTCCCGGCCTCCCGCCCCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACGGCTGACGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCAT",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 8,
    "Order": "Hypocreales",
    "Family": "Hypocreaceae",
    "Genus species": "Trichoderma spp.",
    "Strain": "GOCV2.1",
    "Host species": "Warszewiczia coccinea",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "CGTTGGTGACAGCGGAGGGATCATTACCGAGTTTACAACTCCCAAACCCAATGTGAACGTTACCAAACTGTTGCCTCGGCGGGATCTCTGCCCCGGGTGCGTCGCAGCCCCGGACCAAGGCGCCCGCCGGAGGACCAACCAAAACTCTTTTTGTATACCCCCTCGCGGGTTTTTTATAATCTGAGCCTTCTCGGCGCCTCTCGTAGGCGTTTCGAAAATGAATCAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGTATTCTGGCGGGCATGCCTGTCCGAGCGTCATTTCAACCCTCGAACCCCTCCGGGGGGTCGGCGTTGGGGATCGGCCCTGCCTTTGGCGGTGGCCGTCTCCGAAATACAGTGGCGGTCTCGCCGCAGCCTCTCCTGCGCAGTAGTTTGCACACTCGCATCGGGAGCGCGGCGCGTCCACAGCCGTTAAACACCCAACTTCTGAAATGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 9,
    "Order": "Hypocreales",
    "Family": "Nectriaceae",
    "Genus species": "Fusarium concentricum",
    "Strain": "GOCV2.6",
    "Host species": "Warszewiczia coccinea",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GGATCATTACCGAGTTTACAACTCCCAAACCCCTGTGAACATACCAATTGTTGCCTCGGCGGATCAGCCCGCTCCCGGTAAAACGGGACGGCCCGCCAGAGGACCCCTAAACTCTGTTTCTATATGTAACTTCTGAGTAAAACCATAAATAAATCAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCAAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGTATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCCCTCGGGTTTGGTGTTGGGGATCGGCGAGCCCTTGCGGCAAGCCGGCCCCGAAATCTAGTGGCGGTCTCGCTGCAGCTTCCATTGCGTAGTAGTAAAACCCTCGCAACTGGTACGCGGCGCGGCCAAGCCGTTAAACCCCCAACTTCTGAATGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCAT",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 10,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum theobromicola",
    "Strain": "GOCV3.1",
    "Host species": "Warszewiczia coccinea",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "MCTGAAGTTTACGCTYCTATWACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGCGACCCTCCCGGCCTCCCGCCTCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGC",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 11,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum spp.",
    "Strain": "GOEN2.3",
    "Host species": "Macrocnemum roseum",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GTTTACGCTCTATAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGCGACCCTCCCGGCCTCCCGCCTCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCC",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 12,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum spp.",
    "Strain": "GOFN1.2B",
    "Host species": "Rustia costaricensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "CGGAGGGATCATTACTGAGTTTACGCTCTACAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGCGACCCTCCCGGCCTCCCGCCTCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 13,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum spp.",
    "Strain": "GOFN1.3",
    "Host species": "Rustia costaricensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "CGGAGGGATCATTACTGAGTTTACGCTCTACAACCCTTTGTGAACATACCTACAACTGTTGCTTCGGCGGGTAGGGTCTCCGTGACCCTCCCGGCCTCCCGCCCCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 14,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum spp.",
    "Strain": "GOFN3.1",
    "Host species": "Rustia costaricensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "CGGAGGGATCATTACTGAGTTTACGCTCTATAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGCGACCCTCCCGGCCTCCCGCCTCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 15,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum tropicale",
    "Strain": "GOFV3.4",
    "Host species": "Rustia costaricensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "CGGAGGGATCATTATCGAGTTACCACTCTATAACCCTTTGTGAACATACCTACATGTTGCTTCGGCGGTCGGCCCCCCGGGCCCCCGGCCCCGCTCACGCGGGGCGTCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGCACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCACTGCTTGGTGTTGGGGCTCTACGGTTGACGTAGGCCCCCAAAACTAGTGGCGGACCCTCTCGGAGCCTCCTTTGCGTAGTAACTTTTGTCTCGCACTGGGATTCGGAGGGATTCTAGCCGTTAAACCCCCAATTTTCTAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 16,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum theobromicola",
    "Strain": "GOFV3.5",
    "Host species": "Rustia costaricensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "ACGCTCTACAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGTGACCCTCCCGGCCTCCCGCCCCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCC",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 17,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum gloeosporioides",
    "Strain": "GOGN2.8",
    "Host species": "Sommera donnell-smithii",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "TTACGCTCTATAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGCGACCCTCCCGGCCTCCCGCCTCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATAC",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 18,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum spp.",
    "Strain": "GOHV2.2",
    "Host species": "Simira maxonii",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "CAGCGGAGGGATCATTACTGAGTTTACGCTCTATAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGCGACCCTCCCGGCCTCCCGCCTCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATAT",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 19,
    "Order": "Xylariales",
    "Family": "Xylariaceae",
    "Genus species": "Xylaria multiplex",
    "Strain": "GOIN1.5a",
    "Host species": "Chimarrhis latifolia",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "AGCGGAGGGATCATTAAAGAGTTTTGATAACTCCTAAAACCCATGTGAACTTACCTTCTGTTGCCTCGGCAGGTCGTGTCTACCCTGTGGTCTCCTACCCTGTAGGAGACTACCTGGTGGTCACGGGTCTACCTGTCAGTGGCCCGTTAAACTCTGTTTATTATATGCCATTCTGAATTATCAACTAAATAAGTTTAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCATTAGTATTCTAGTGGGCATGCCTGTTCGAGCGTCATTTCAACCCTTAAGCCTTTGTTGCTTAGTGTTGGGAGCCTACGGCCTTCTGTAGCTCCTTAAAGTTAGTGGCAGAGTTGGTTTACACTCTAGACGTAGTAGATTCTATCTCGTCTGTAGTTAGGCCGGTCTCTTGCCGTAAAACCCCCCTAAATTTTTTTTTAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATAT",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 20,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum gloeosporioides",
    "Strain": "GOIN2.5",
    "Host species": "Chimarrhis latifolia",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "AGTTACCGCTCCTTATAACCCTTTGTGAACATACCTCAAACGTTGCCTCGGCGGGCAGCCGGAGCCCAGCTCCGGCGCCCGGAGCCGCCTTCTCGGCGCGCCCCACCCGCCGGCGGACCACTAAACTCTATTGCAACGACGTCTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCACCGCTTGGCGTTGGGGCCCTACGGCTTCCGTAGGCCCCGAAATACAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACATACCACCTCGCACTGGGATCCGGAGGGACTCCTGCCGTAAAACCCCCCAATTTATCAAGGTTGACCTCGGATCAGGTAGGAATACCC",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 21,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum spp.",
    "Strain": "GOIN3.2",
    "Host species": "Chimarrhis latifolia",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GGATCATTACTGAGTTTACGCTCTATAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGCGACCCTCCCGGCCTCCCGCCTCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCAT",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 22,
    "Order": "Xylariales",
    "Family": "Sporocadaceae",
    "Genus species": "Pseudopestalotiopsis elaeidis",
    "Strain": "GOIV2.4b",
    "Host species": "Chimarrhis latifolia",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GGAGGGATCATTATAGAGTTTTCTAAACTCCCAACCCATGTGAACTTACCTTTTGTTGCCTCGGCAGAGGTTACCTGGTACCTGGAGACAGGTTACCCTGTAGCAACTGCCGGTGGACTACTAAACTCTTGTTATTTTATGTAATCTGAGCGTCTTATTTTAATAAGTCAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCATTAGTATTCTAGTGGGCATGCCTGTTCGAGCGTCATTTCAACCCTTAAGCCTAGCTTAGTGTTGGGAATTTACAGTTATGTAATTCCTGAAATACAACGGCGGATCTGTGGTATCCTCTGAGCGTAGTAAATTATTTCTCGCTTTTGTTAGGTGCTGCAGCTCCCAGCCGCTAAACCCCCAATTTTTTGTGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 23,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum gloeosporioides",
    "Strain": "GOJN1.1",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GATCATTACTGAGTTTACGCTCTATAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGCGACCCTCCCGGCCTCCCGCCTCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATAT",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 24,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum theobromicola",
    "Strain": "GOJN1.2",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GGATCATTACTGAGTTTACGCTCTACAACCCTTTGTGAACATACCTACAACTGTTGCTTCGGCGGGTAGGGTCCCCGTGACCCTCCCGGCCTCCCGCCCCCCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 25,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum tropicale",
    "Strain": "GOJN1.3",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "ATTATCGAGTTACCACTCTATAACCCTTTGTGAACATACCTACATGTTGCTTCGGCGGTCGGCCCCCCGGGCCCCCGGCCCCGCTCACGCGGGGCGTCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGCACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCACTGCTTGGTGTTGGGGCTCTACGGTTGACGTAGGCCCCCAAAACTAGTGGCGGACCCTCTCGGAGCCTCCTTTGCGTAGTAACTTTTGTCTCGCACTGGGATTCGGAGGGATTCTAGCCGTTAAACCCCCAATTTTCTAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 26,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum theobromicola",
    "Strain": "GOJN1.4",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GATCATTACTGAGTKACCGCTCTATAACCCTTTGTGAACATACCTATAACCGTTGCTTCGGCGGGCGGCGCGGCCACTGCGGCCCGCCCCCCGGCCCCGCTCGTCGGGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGCACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACGGCTGACGTAGGCCCTCAAAGGTAGTGGCGGACCCTCTCGGAGCCTCCTTTGCGTAGTAACTTTTGTCTCGCACTGGGATCCGGAGGGGCTTCTGGCCGTAAAACCCCCAAACTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 27,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum gloeosporioides",
    "Strain": "GOJN2.2",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "ATCATTACTGAGTTTACGCTCTACAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGTGACCCTCCCGGCCTCCCGCCCCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 28,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum tropicale",
    "Strain": "GOJN2.4A",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GGAGGGATCATTACTGAGTTTACGCTCTACAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGCGACCCTCCCGGCCTCCCGCCTCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCAATAA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 29,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum theobromicola",
    "Strain": "GOJN2.4B",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "ACCAGCGGAGGGATCATTACTGAGTTTACGCTCTATAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGTAGGGTCTCCGCGACCCTCCCGGCCTCCCGCCTCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACAGCTGATGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 30,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum gloeosporioides",
    "Strain": "GOJN3.2",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GGAGGGATCATTATCGAGTTACCGCTCCTTATAACCCTTTGTGAACATACCTCAAACGTTGCCTCGGCGGGCAGCCGGAGCCCAGCTCCGGCGCCCGGAGCCGCCTTCTCGGCGCGCCCCACCCGCCGGCGGACCACTAAACTCTATTGCAACGACGTCTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCACCGCTTGGCGTTGGGGCCCTACGGCTTCCGTAGGCCCCGAAATACAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACATACCACCTCGCACTGGGATCCGGAGGGACTCCTGCCGTAAAACCCCCCAATTTATCAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATC",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 31,
    "Order": "Diaporthales",
    "Family": "Diaporthaceae",
    "Genus species": "Diaporthe aff. phaseolorum",
    "Strain": "GOJN3.6",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GGATCATTGCTGGAACGCGCTTCGGCGCACCCAGAAACCCTTTGTGAACTTATACCTATTTGTTGCCTCGGCGTAGGCCGGCCTCTTCACTGAGGCCCCCTGGAAACAGGGAGCAGCCCGCCGGCGGCCAACTAAACTCTTGTTTCTATAGTGAATCTCTGAGTAAAAACATAAATGAATCAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCTCTGGTATTCCGGAGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCCTGGCTTGGTGATGGGGCACTGCCTTCTAGCGAGGGCAGGCCCTGAAATCTAGTGGCGAGCTCGCTAGGACCCCGAGCGTAGTAGTTATATCTCGTTCTGGAAGGCCCTGGCGGTGCCCTGCCGTTAAACCCCCAACTTCTGAAAATTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCAAT",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 32,
    "Order": "Xylariales",
    "Family": "Xylariaceae",
    "Genus species": "Xylaria cubensis",
    "Strain": "GOJV1.1",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "TTAAAGAGTTCTATAACTCCCAAACCCATGTGAACATACCTAACGTTGCCTCGGCAGGTCGCGCCTACCTCGTAGTCTTACCCTGTAAGACTTACCCGGGAGGTGCGGGTAAGCCTGCCGGCGGCCCACCAAACTCTGTTTGATATTGAATTCTGAACCTATAACTAAATAAGTTAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCATATTGCGCCCATTAGTATTCTAGTGGGCAGGACCGTTCGAGCGTCATTTCAACCCTTAAGCCTTAGTTGCTTAGTGTTGGGAGCCTACGGTAACGTAGCTCCTCAAAGTTAGTGGCGGAGTTGGTTCACACTCTAGACGTAGTAATTTTTTATCTCGCCTATTAGTTGGACTGATCCCCTGCCGTAAAACCCCAACTTCTCAAGGTTGACCTCGAATCGGTTCAGACAAACTCGCTAAATTGAAGCATATCAATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 33,
    "Order": "Pleosporales",
    "Family": "Pleosporaceae",
    "Genus species": "Curvularia reesei",
    "Strain": "GOJV1.3",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GAGGGATCATTACACAAAAAAATATGAAGGCTGTACGCAGCTGAATTATTTTTCACCCATGTCTTTTGCGCACTTGTTGTTTCCTGGGCGGGTTCGCCCGCCACCAGGACCACACCATAAACCTTTTTTATGCAGTTGCAATCAGCGTCAGTAAAACAAGTAATTAATTTACAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATACGTAGTGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCTTTGGTATTCCAAAGGGCATGCCTGTTCGAGCGTCATTTGTACCCTCAAGCTCTGCTTGGTGTTGGGCGTTTTTGTTATCCTTTTTGGGGGGTAACTCGCCTTAAAACAATTGGCAGCCGGCCTACTGGTTTCGGAGCGCAGCACAATTTTGCGCTTGCAACCAGCAAAAGAGGTTGGCGATCCATCAAGTCTACTTTTCTCACTTTTGACCTCGGATCAGGTAGGGATACCCGCTGAACTTAAGCATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 34,
    "Order": "Hypocreales",
    "Family": "Nectriaceae",
    "Genus species": "Fusarium concentricum",
    "Strain": "GOJV1.5",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "TACCGAGTTTACAACTCCCAAACCCCTGTGAACATACCAATTGTTGCCTCGGCGGATCAGCCCGCTCCCGGTAAAACGGGACGGCCCGCCAGAGGACCCCTAAACTCTGTTTCTATATGTAACTTCTGAGTAAAACCATAAATAAATCAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCAAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGTATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCCCTCGGGTTTGGTGTTGGGGATCGGCGAGCCCTTGCGGCAAGCCGGCCCCGAAATCTAGTGGCGGTCTCGCTGCAGCTTCCATTGCGTAGTAGTAAAACCCTCGCAACTGGTACGCGGCGCGGCCAAGCCGTTAAACCCCCAACTTCTGAATGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 35,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum spp.",
    "Strain": "GOJV1.6",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GGGATCATTACTGAGTTACCGCTCTATAACCCTTTGTGAACATACCTACAACTGTTGCTTCGGCGGGTAGGCCGTCCCCTGAAAAGGACGCCTCCCGGCCCGGACCGGACCCCCCGCGGGACCGGACCCGGCGCCCGCCGGAGGATAACCAAACTCTATTGTAACGACGTTTCTTCTGAGTGGCATAAGCAAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCTCTACGGTCGACGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACATTTCGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCAATAA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 36,
    "Order": "Xylariales",
    "Family": "Xylariaceae",
    "Genus species": "Nemania aff. abortiva",
    "Strain": "GOJV2.2",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "CATAAAACTCCCAAACCCCATGTGAACCTACCTTCTGTTGCCTCGGCAGGTCGCGCCGGCGCTCAACCGCGTAGCACCCTACCCTGTAGGGCCTACCCGGGACGCGCCGGCGGCTCCCCTGCCGGCGGCCCACGAAACTCTGTTTAGCATATTGAATTCTGAACGTATAACTAAATCAGTTAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCATTAGTATTCTAGTGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCCCCCGTTGCTTGGTGTTGGGAGCCTACGGGGTCACCCGTAGCTCCTTAAAGTTAGTGGCGGAGTCGGTTCACACTCTAGACGTAGTAGATTTTCACCTCGCCTGTAGTGCTGGACCGGTCCCCTGCCGTAAAACGCCCCTATTTCTAAAAGGTTGACCTCGGATCAGGTAGGAATAC",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 37,
    "Order": "Xylariales",
    "Family": "Hypoxylaceae",
    "Genus species": "Hypomontagnella monticulosa",
    "Strain": "GOJV2.5",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "CGGAGGGATCATTAAAGAGTTTTTACAACTCCCAAACCCCTGTGAACGTACCTCTTGTTGCCTCGGCAGGCCTCGCGCCACCCTGTAGCCCCTACGTTGTAGGGCCTACCCGGGGAGCGCGGGGGTCCCTGCCGGCGGCCTGCGAAACTCTGTTTAGCACTGAATTCTGAATATATAACTAAATAAGTTAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCATTAGTATTCTAGTGGGCATGCCTGTTCGAGCGTCATTTCAACCCTTAAGCCCCTGTTGCTTAGCGTTGGGAGCCTACGGCAGCGTAGCTCCCCAAAGTTAGTGGCGTGGTCGGTTCACACTCCAGACGTAGTAAATTTTCACCTCGCCTGTAGTCGGACCGGTCCCCTGCCGTAAAACACCCCAATTTTTAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATAT",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 38,
    "Order": "Xylariales",
    "Family": "Xylariaceae",
    "Genus species": "Xylaria multiplex",
    "Strain": "GOJV3.2A",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "TCATTAAAGAGTTTTGATAACTCCTAAAACCCATGTGAACTTACCTTCTGTTGCCTCGGCAGGTCGTGTCTACCCTGTGGTCTCCTACCCTGTAGGAGACTACCTGGTGGTCACGGGTCTACCTGTCAGTGGCCCGTTAAACTCTGTTTATTATATGCCATTCTGAATTATCAACTAAATAAGTTTAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCATTAGTATTCTAGTGGGCATGCCTGTTCGAGCGTCATTTCAACCCTTAAGCCTTTGTTGCTTAGTGTTGGGAGCCTACGGCCTTCTGTAGCTCCTTAAAGTTAGTGGCAGAGTCGGTTTACACTCTAGACGTAGTAGATTCTATCTCGTCTGTAGTTAGGCCGGTCTTTTGCCGTAAAACCCCCCTAAAATTTTTTTTAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCAATAAGCG",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 39,
    "Order": "Botryosphaeriales",
    "Family": "Botryosphaeriaceae",
    "Genus species": "Lasiodiplodia euphorbicola",
    "Strain": "GOJV3.4",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GAAGGATCATTACCGAGTTTTCGGGCTTCGGCTCGACTCTCCCACCCTTTGTGAACGTACCTCTGTTGCTTTGGCGGCTYCGGCCGCCAAAGGACCTTCAAACTCCAGTCAGTAAACGCAGACGTCTGATAAACAAGTTAATAAACTAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCCTTGGTATTCCGGGGGGCATGCCTGTTCGAGCGTCATTACAACCCTCAAGCTCTGCTTGGAATTGGGCACCGTCCTCACTGCGGACGCGCCTCAAAGACCTCGGCGGTGGCTGTTCAGCCCTCAAGCGTAGTAGAATACACCTCGCTTTGGAGCGGTTGGCGTCGCCCGCCGGACGAACCTTCTGAACTTTTCTCAAGGTTGACCTCGGATCAGGTAGGGATACCCGCTGAACTTAAGCATATCA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 40,
    "Order": "Xylariales",
    "Family": "Sporocadaceae",
    "Genus species": "Neopestalotiopsis drenthii",
    "Strain": "GOJV3.5A",
    "Host species": "Psychotria panamensis",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "ATTATAGAGTTTTCTAAACTCCCAACCCATGTGAACTTACCTTTTGTTGCCTCGGCAGAAGTTATAGGTCTTCTTATAGCTGCTGCCGGTGGACCATTAAACTCTTGTTATTTTATGTAATCTGAGCGTCTTATTTTAATAAGTCAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCATTAGTATTCTAGTGGGCATGCCTGTTCGAGCGTCATTTCAACCCTTAAGCCTAGCTTAGTGTTGGGAATCTACTTCTCTTAGGAGTTGTAGTTCCTGAAATACAACGGCGGATTTGTAGTATCCTCTGAGCGTAGTAATTTTTTTCTCGCTTTTGTTAGGTGCTATAACTCCCAGCCGCTAAACCCCCAATTTTTTGTGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCAAT",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 41,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum theobromicola",
    "Strain": "GOKN3.2",
    "Host species": "Notopleura polyphebia",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "TCTATAACCCTTTGTGAACATACCTACAACTGTTGCTTCGGCGGGTAGGCCGTCCCCTGAAAAGGACGCCTCCCGGCCCGGACCGGACCCCCCGCGGGACCGGACCCGGCGCCCGCCGGAGGATAACCAAACTCTATTGTAACGACGTTTCTTCTGAGTGGCATAAGCAAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCTCTACGGTCGACGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACATTTCGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATAC",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 42,
    "Order": "Hypocreales",
    "Family": "Cordycipitaceae",
    "Genus species": "Lecanicillium coprophilum",
    "Strain": "GOKV1.2A",
    "Host species": "Notopleura polyphebia",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "CGGAGGGATCATTACCGAGTTTACAACTCCCAAACCCATGTGAACATACCTTACAGTTGCTTCGGCGGAGCCGCCCCGGCGCCCGGAACCCAGTTTCGCGGCCCGGACCAAGGCGCCCGCCGGAGGCCACAAACTCTTCTGTTTTTACAGTATCTTCTGAGTGTGCCGCAAGGCAAAAACAAATGAATCAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGAATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCGGTCTCCCTCCGGGAGAGACCGGCGTTGGGGACCGGCACTTACCCCGCCGGCCCCGAAATGAAGTGGCGGCCCGTCCGCGGCGACCTCTGCGTAGTAACTCCACTCGCACCGGGACCCGGGCGCGGCCACGCCGTAAAACCCCCAACTTCCGAATGTTGACCTCGAATCAGGTAGGAATACCCGCTGAACTTAA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 43,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum theobromicola",
    "Strain": "GOKV3.4",
    "Host species": "Notopleura polyphebia",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "CGGAGGGATCATTACTGAGTTACCGCTCTATAACCCTTTGTGAACATACCTATAACCGTTGCTTCGGCGGGCGGGCGCGGCCACTGCGGCCCGCGCCCCCCCGGCCCCGCTCGTCGGGGCGCCCGCCGGAGGATAACCATACTCTGATTTAACGACGTTTCTTCTGAGTGGCACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGCGTTGGGGCCCTACGGCTGACGTAGGCCCTCAAAGGTAGTGGCGGACCCTCTCGGAGCCTCCTTTGCGTAGTAACTTTTGTCTCGCACTGGGATCCGGAGGGGCTTCCGGCCGTAAAACCCCCCAATTTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCAATAA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 44,
    "Order": "Hypocreales",
    "Family": "Hypocreaceae",
    "Genus species": "Trichoderma hamatam/harzianum",
    "Strain": "GOLV3.2",
    "Host species": "Posoqueria grandiflora",
    "Location": "Costa Rica",
    "ITS seq (Sanger seq from sample)": "GAGGGATCATTACCGAGTTTACAACTCCCAAACCCAATGTGAACGTTACCAAACTGTTGCCTCGGCGGGATCTCTGCCCCGGGTGCGTCGCAGCCCCGGACCAAGGCGCCCGCCGGAGGACCAACCAAAACTCTTTTTGTATACCCCCTCGCGGGTTTTTTATAATCTGAGCCTTCTCGGCGCCTCTCGTAGGCGTTTCGAAAATGAATCAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGTATTCTGGCGGGCATGCCTGTCCGAGCGTCATTTCAACCCTCGAACCCCTCCGGGGGGTCGGCGTTGGGGATCGGCCCTGCCTTTGGCGGTGGCCGTCTCCGAAATACAGTGGCGGTCTCGCCGCAGCCTCTCCTGCGCAGTAGTTTGCACACTCGCATCGGGAGCGCGGCGCGTCCACAGCCGTTAAACACCCAACTTCTGAAATGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCAATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 45,
    "Order": "Xylariales",
    "Family": "Sporocadaceae",
    "Genus species": "Pestalotiopsis theae",
    "Strain": "LME402.1",
    "Host species": "??",
    "Location": "??",
    "ITS seq (Sanger seq from sample)": "GCGGAGGGATCATTATAGAGTTTTCTAAACTCCCAACCCATGTGAACTTACCTTTTGTTGCCTCGGCAGAGGTTACCTGGTACCTGGAGACAGGTTACCCTGTAGCAACTGCCGGTGGACTACTAAACTCTTGTTATTTTATGTAATCTGAGCGTCTTATTTTAATAAGTCAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCATTAGTATTCTAGTGGGCATGCCTGTTCGAGCGTCATTTCAACCCTTAAGCCTAGCTTAGTGTTGGGAATTTACAGTTATGTAATTCCTGAAATACAACGGCGGATCTGTGGTATCCTCTGAGCGTAGTAAATTATTTCTCGCTTTTGTTAGGTGCTGCAGCTCCCAGCCGCTAAACCCCCAATTTTTTGTGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATAT",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 46,
    "Order": "Hypocreales",
    "Family": "Hypocreaceae",
    "Genus species": "Trichoderma spp.",
    "Strain": "LMS103.3",
    "Host species": "Coffea arabica",
    "Location": "??",
    "ITS seq (Sanger seq from sample)": "GAGGGATCATTACCGAGTTTACACTCCCWAACCCMATGTGAACKTTACCAAACTGTTGCCTCGGCGGGATCTCTGCCCCGGGTGCGTCGCAGCCCCSGACCAAGGCSCCCGCCSGAKGACCAACCAAAACTCTTATTGTATACCCCCTCGCGGGTTTTTTTATAATCTGAGCCTTCTCGGCSCCKCTCSTAGGCKTTTCGAAAATGAATCAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCKATRAARAACGCASCKAAATGCSATAAGTAATGTGAATTGCWKAATTCAGTGAATCATCKAATCTTTGAACKCMCATTGYGCCCGCCMGTATTCTGGCGGGCATGCCTGTCCGAGCGTCATTTCAACCCTCSAACCCCTCCGGGGGGTCGGSGTTGGGGATCGGCCCTCCCTTAGCGGGGGCCGTCTCCGAAATACAGTGGCGGTCTCGCCGCASCCTCTCCTGCGCAGTAGTTTGCACACTCRCATCGGGAGCGCGGCGCGTCCACAGCCGTTAAACACCCAACTTTCTGAAATGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 47,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum tropicale",
    "Strain": "LMS1N3.3",
    "Host species": "Coffea arabica",
    "Location": "??",
    "ITS seq (Sanger seq from sample)": "GTGACCAGCGGAGGGATCATTATCGAGTTACCGCTCCTTATAACCCTTTGTGAACATACCTCAAACGTTGCCTCGGCGGGCAGCCGGAGCCCAGCTCCGGCGCCCGGAGCCGCCTTCTCGGCGCGCCCCACCCGCCGGCGGACCACTAAACTCTATTGCAACGACGTCTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCACCGCTTGGCGTTGGGGCCCTACGGCTTCCGTAGGCCCCGAAATACAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAGCATACCACCTCGCACTGGGATCCGGAGGGACTCCTGCCGTAAAAACCCCCAATTTATCAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCAATAA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 48,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum costaricense",
    "Strain": "LPF1",
    "Host species": "Coffea arabica",
    "Location": "??",
    "ITS seq (Sanger seq from sample)": "AGTTACCGCTCTATAACCCTTTGTGAACGTACCTAACCGTTGCTTCGGCGGGCAGGGGAAGCCTCTCGCGGGCCTCCCCTCCCGGCGCCGGCCCCCACCACGGGGACGGGGCGCCCGCCGGAGGAAACCAAACTCTATTTACACGACGTCTCTTCTGAGTGGCACAAGCAAATAATTAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCTCGCCAGCATTCTGGCGAGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCACCGCTTGGTTTTGGGGCCCCACGGCACACGTGGGCCCTTAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTAACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCAAATTTTTTACAGGTTGACCTCGGATCAGGTAGGAATAC",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 49,
    "Order": "Agaricales",
    "Family": "Mycenaceae",
    "Genus species": "Mycena citricolor",
    "Strain": "Mycena",
    "Host species": "??",
    "Location": "??",
    "ITS seq (Sanger seq from sample)": "GGAGGATCATTATTGAATTAAACACGGTTCGTTGATGCTGGCCTYCSGGSMTGGGGTCCSSGAATTTGATTCCTTCCCCTCTTCTTGGGCACCTTTTGGWRKCCCCTGGGAACCGGTGGGAAGGTTTCCCCAATCCCTCTGGGTCCRTWACCGGAGTCTTTGTTCCTATTCCCAACTTCRTCTTCRAATGGTCTTCTTRTGGCCSKTWAAAAAGTCATTAAACCTATACAACTTTCAGCAACGGATCTCTTGGCTCTCCCATCGATGAARAACGCAGCGAAATGCGATAAGTAATGKGAATTGCARAATTCAGTGAATCATCGAATCTTTGAACGCACCTTGCGCCCTTTGGTATTCCGAAGGGCATGCCTGTTTGAGTGTCATTAAATTCTCAACCTTCAAAACTTTGTTGATTTGAAGTGTTGGATTTTGAGGGTCTTTGCTGGCTTCCTTTAGTGGATGGTCTGCTCCCTTTAAAAGCATTAGTAGGATCCTTTGTGGATTTGTGAATTGCCAACTTGGTTGTGATAATTATCTACGGTCAAGCTCTGGTATTCGCGATTGAAGCAAAACCTGTGGGAACCTGCTTATAACCGTTCAGCAATGAACAACAAATTTATTGACTTGACCTCAAATCAGGTAGGACTACCCGCTGAACTTAAGCA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 50,
    "Order": "Glomerellales",
    "Family": "Glomerellaceae",
    "Genus species": "Colletotrichum theobromicola",
    "Strain": "SM1S2N2.7",
    "Host species": "Coffea arabica",
    "Location": "??",
    "ITS seq (Sanger seq from sample)": "CAGCGGAGGGATCATTACTGAGTTTACGCTCTACAACCCTTTGTGAACATACCTATAACTGTTGCTTCGGCGGGCAGGGTCTCCGTGACCCTCCCGGCCTCCCGCCCCCGGGCGGGTCGGCGCCCGCCGGAGGATAACCAAACTCTGATTTAACGACGTTTCTTCTGAGTGGTACAAGCAAATAATCAAAACTTTTAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCGCCAGCATTCTGGCGGGCATGCCTGTTCGAGCGTCATTTCAACCCTCAAGCTCTGCTTGGTGTTGGGGCCCTACGGCTGACGTAGGCCCTCAAAGGTAGTGGCGGACCCTCCCGGAGCCTCCTTTGCGTAGTAACTTTACGTCTCGCACTGGGATCCGGAGGGACTCTTGCCGTAAAACCCCCAATTTTCCAAAGGTTGACCTCGGATCAGGTAGGAATACCCGCTGAACTTAAGCATATCAATAA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  },
  {
    "id": 51,
    "Order": "Xylariales",
    "Family": "Hypoxylaceae",
    "Genus species": "Hypomontagnella monticulosa",
    "Strain": "SM2S4N2.8",
    "Host species": "Coffea arabica",
    "Location": "??",
    "ITS seq (Sanger seq from sample)": "TTGGTGACCAGCGGAGGGATCATTACTGAGTTATCCAAAACTCCCAACCCTTTGTGAACTTACCACTGTTGCCTCGGCGAGTTGTGCTACCCTATAGCTACCCTGTAGCTACCCGGGAACACATTCCAAGCTCGCCAGAGGACCTACCAACTCTGTTTTATACTGTATCTCTGAACTTTATAACTAAATACGTTAAAACTTTCAACAACGGATCTCTTGGTTCTGGCATCGATGAAGAACGCAGCGAAATGCGATAAGTAATGTGAATTGCAGAATTCAGTGAATCATCGAATCTTTGAACGCACATTGCGCCCATTAGTATTCTAGTGGGCATGCCTATTCGAGCGTCATTTCAACCCTTAAGCCTCAGTTGCTTAGCGTTGGGACTCTACGACCTATGATGGCGTAGTTCCTTAAAGGTAGTGGCGGAGTTATAGCACACTCTAAGCGTAGTAATTCTCTCTCGCTTTTTGTAGTGGTTATAGTTGCTAGCCATAAAACACCCCCTATTTTAATGGTTGACCTCGGATTAGGTAGGAATACCCGCTGAACTTAAGCATATCAATA",
    "Genome": null,
    "Metabolome": null,
    "Clusters": null,
    "Citations": null
  }
]
