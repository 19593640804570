[
  {
    "Tribe": "",
    "Species": "Ronabea latifolia",
    "Country": "Costa Rica",
    "Microbiome": "",
    "Genome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 1
  },
  {
    "Tribe": "",
    "Species": "Isertia haenkeana",
    "Country": "Costa Rica",
    "Genome": "",
    "Microbiome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 2
  },
  {
    "Tribe": "",
    "Species": "Warszewiczia coccinea",
    "Country": "Costa Rica",
    "Microbiome": "",
    "Genome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 3
  },
  {
    "Tribe": "",
    "Species": "Posoqueria latifolia",
    "Country": "Costa Rica",
    "Microbiome": "",
    "Genome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 4
  },
  {
    "Tribe": "",
    "Species": "Macrocnemum roseum",
    "Country": "Costa Rica",
    "Microbiome": "",
    "Genome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 5
  },
  {
    "Tribe": "",
    "Species": "Rustia costaricensis",
    "Country": "Costa Rica",
    "Microbiome": "",
    "Genome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 6
  },
  {
    "Tribe": "",
    "Species": "Sommera donnell-smithii",
    "Country": "Costa Rica",
    "Microbiome": "",
    "Genome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 7
  },
  {
    "Tribe": "",
    "Species": "Simira maxonii",
    "Country": "Costa Rica",
    "Microbiome": "",
    "Genome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 8
  },
  {
    "Tribe": "",
    "Species": "Chimarrhis latifolia",
    "Country": "Costa Rica",
    "Microbiome": "",
    "Genome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 9
  },
  {
    "Tribe": "",
    "Species": "Psychotria panamensis",
    "Country": "Costa Rica",
    "Microbiome": "",
    "Genome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 10
  },
  {
    "Tribe": "",
    "Species": "Notopleura polyphlebia",
    "Country": "Costa Rica",
    "Microbiome": "",
    "Genome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 11
  },
  {
    "Tribe": "",
    "Species": "Posoqueria grandiflora",
    "Country": "Costa Rica",
    "Microbiome": "",
    "Genome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 12
  },
  {
    "Tribe": "",
    "Species": "Coffea arabica",
    "Country": "Costa Rica",
    "Microbiome": "",
    "Genome": "",
    "Proteome": "",
    "Voucher (collector, collector number, herbarium)": "",
    "Barcode of herbarium voucher or silica collection (*); accession number of living plant (**) or sequence (***)": "",
    "id": 13
  }
]
